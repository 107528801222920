import {createSlice} from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        data: null,
        isAuthenticated: false,
        isLoading: true,
        hasChangedMap: false
    },
    reducers: {
        startLoading: (state, action) => {
            return { ...state, data: null, isAuthenticated: false, isLoading: true };
        },
        login: (state, action) => {
            return { ...state, data: action.payload, isAuthenticated: true, isLoading: false };
        },
        logout: (state, action) => {
            return { ...state, data: null, isAuthenticated: false, isLoading: false };
        },
        setHasChangedMap: (state, action) => {
            return { ...state, hasChangedMap: action.payload };
        }
    }
})

export const {
    login,
    logout,
    startLoading,
    setHasChangedMap
} = userSlice.actions;

export default userSlice.reducer;

export const selectUserToken = state => state.user.data?.access_token;
export const selectUsername = state => state.user.data?.profile?.preferred_username ?? '';
export const selectAuthenticated = state => state.user.isAuthenticated;
export const selectIsLoading = state => state.user.isLoading;
export const selectHasChangedMap = state => state.user.hasChangedMap;
export const selectCurrentScenarioId = state => state?.demo?.keplerGl?.map?.visState?.changes?.scenarioId;
